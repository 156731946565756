import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {} from '@fortawesome/react-fontawesome'

import 'typeface-montserrat'
import 'typeface-merriweather'
import './src/normalize.css'
import './src/style.css'
import './src/datepicker.css'

library.add(fas)
